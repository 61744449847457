import { ErrorHandler, Injectable, NgZone } from '@angular/core';

@Injectable()
// @Injectable({ providedIn: CoreModule })
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private zone: NgZone) {}
  // constructor(private zone: NgZone, private notificationService: NotificationService) {}

  handleError(err: Error) {
    this.zone.run(
      () => {
        // const msg = err.message || 'Undefined client error';
        // return this.notificationService.showError(msg + ' (glob error handler)');
      },
      // () => this.notificationService.showError(err.message || 'Undefined client error'),
    );
    console.error('GlobalErrorHandler says:', err);
  }
}
