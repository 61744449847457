import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export class CustomValidators {
  static ComparePasswordsValidator: ValidatorFn = (
    control: AbstractControl,
  ): ValidationErrors | null => {
    const newPassword1 = control.get('new_password1');
    const newPassword2 = control.get('new_password2');
    return newPassword1 && newPassword2 && newPassword1.value === newPassword2.value
      ? null
      : { comparePasswords: true };
  };
}

//  Custom ErrorStateMatcher which returns true (error exists)
//  when the parent form group is invalid and the control has been touched

export class ConfirmValidParentMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null): boolean {
    return control && control.parent ? control.parent.invalid && control.touched : false;
  }
}

// Collection of reusable RegExps

export const regExps: { [key: string]: RegExp } = {
  phone: /^[(]{0,1}[0-9]{3}[)-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/,
  phone_replace_rm: /[()-\s]/g,
  phone_replace_add: /(\d{3})(\d{3})(\d{4})/,
  // TODO use the following email pattern
  email:
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/,
  // password: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/,
};

//  Collection of reusable error messages

export const errorMessages: { [key: string]: string } = {
  mandatoryField: 'This field is mandatory',
  matchPassword: 'Passwords must match',
  email: 'Email must be a valid email address (username@domain)',
  passwordLength: 'Password must be longer',
  phone: 'Phone should be valid & 10-digit',
  save_reminder: "Click the Save button so you don't lose your changes",
};

export const noSymbolsPhone = (phone: string) => phone.replace(regExps.phone_replace_rm, '');
export const preparePhoneForShow = (phone: string) =>
  phone.replace(regExps.phone_replace_add, '($1)$2-$3');
