import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NotFoundMsg } from '../../constants/not-found-msg.enum';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundComponent {
  notFoundMessage: string = NotFoundMsg.fourofour;
}
