<div class="slider-holder" *ngIf="imagesAndVideos && imagesAndVideos.length > 0">
  <!-- the following div is for animation -->
  <div class="animation-holder">
    <ng-container *ngFor="let imageOrVideoItem of imagesAndVideos; let i = index">
      <div class="item-holder">
        <ng-container *ngIf="imageOrVideoItem.type === 'PHOTO'">
          <app-view-fullscreen-img
            *ngIf="i === counter"
            [imageURL]="imageOrVideoItem.url"
            [removeBorderRadius]="true"
            [sizeLarge]="true"
          ></app-view-fullscreen-img>
        </ng-container>
        <ng-container *ngIf="imageOrVideoItem.type === 'VIDEO'">
          <app-view-fullscreen-video
            *ngIf="i === counter"
            [videoURL]="imageOrVideoItem.url"
            [removeBorderRadius]="true"
            [sizeLarge]="true"
          ></app-view-fullscreen-video>
        </ng-container>
      </div>
    </ng-container>
  </div>

  <button mat-icon-button class="prev-btn" (click)="onPrevious()" [disabled]="counter === 0">
    <mat-icon>chevron_left</mat-icon>
  </button>

  <button
    mat-icon-button
    class="next-btn"
    (click)="onNext()"
    [disabled]="this.counter === this.imagesAndVideos.length - 1"
  >
    <mat-icon>chevron_right</mat-icon>
  </button>
</div>

<div class="audios-holder" *ngIf="audios && audios.length > 0">
  <div class="audio-item" *ngFor="let audioItem of audios">
    <vm-player theme="dark">
      <!-- <vm-player theme="{{ message.user.id === meUser.id ? 'dark' : 'light' }}"> -->
      <vm-audio>
        <source data-src="{{ audioItem.url }}" type="audio/webm" />
        <source data-src="{{ audioItem.url }}" type="audio/mp4" />
      </vm-audio>
      <vm-ui>
        <vm-controls>
          <vm-playback-control [hideTooltip]="true"></vm-playback-control>
          <vm-current-time></vm-current-time>

          <vm-scrubber-control></vm-scrubber-control>
          <vm-volume-control [hideTooltip]="true"></vm-volume-control>
        </vm-controls>
      </vm-ui>
    </vm-player>
  </div>
</div>
<!-- 
{{ imagesAndVideos | json }} -->
