<!-- <div *ngIf="loading" class="spinner-holder">
  <mat-progress-spinner color="primary" mode="indeterminate"> </mat-progress-spinner>
</div>   -->
<!-- [hidden]="loading" -->
<!-- (load)="onLoad()" -->
<!-- <img (click)="openDialogWithTemplateRef(myDialog)" class="preview-img" [src]="videoURL" /> -->
<div
  (click)="openDialogWithTemplateRef(myDialog)"
  [ngClass]="{ preview: true, no_border: removeBorderRadius, size_large: sizeLarge }"
>
  <vm-player theme="dark">
    <vm-video>
      <source data-src="{{ videoURL }}" type="video/mp4" />
      <source data-src="{{ videoURL }}" type="video/ogg" />
      <source data-src="{{ videoURL }}" type="video/webm" />
      <track />
    </vm-video>
  </vm-player>

  <mat-icon class="play-ico">play_circle_outline</mat-icon>
  <!-- play_circle_outline -->
</div>

<ng-template #myDialog>
  <!-- <img class="full-screen-img" [src]="imageURL" /> -->
  <div class="full-screen-video">
    <vm-player theme="dark">
      <vm-video>
        <source data-src="{{ videoURL }}" type="video/mp4" />
        <source data-src="{{ videoURL }}" type="video/ogg" />
        <source data-src="{{ videoURL }}" type="video/webm" />
        <track />
      </vm-video>
      <vm-ui>
        <vm-controls>
          <vm-playback-control [hideTooltip]="true"></vm-playback-control>
          <vm-current-time></vm-current-time>
          <vm-scrubber-control></vm-scrubber-control>
          <vm-volume-control [hideTooltip]="true"></vm-volume-control>
        </vm-controls>
        <!-- <vm-scrim></vm-scrim> -->
      </vm-ui>
    </vm-player>
  </div>
</ng-template>
