<div class="flex">
  <!-- <div class="data">{{ data?.notification?.body }}</div>  -->
  <div class="data">{{ data?.notification?.title }}</div>

  <button mat-icon-button (click)="goToMessage()">
    <mat-icon>arrow_forward</mat-icon>
  </button>
  <div class="dismiss">
    <button mat-icon-button (click)="snackBarRef.dismiss()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
