<div class="crumbs-holder">
  <h1 *ngIf="header">{{ header }}</h1>
  <button *ngIf="sel_options"
    mat-icon-button
    [matMenuTriggerFor]="menu"
    [matMenuTriggerData]="{ options: sel_options }"
  >
    <mat-icon>keyboard_arrow_down</mat-icon>
  </button>

  <mat-menu #menu="matMenu" xPosition="before">
    <ng-template matMenuContent let-options="options">
      <button
        mat-menu-item
        *ngFor="let option of options"
        (click)="navigateMenuItem(option.id)"
      >
        {{ option.name }}
      </button>
    </ng-template>
  </mat-menu>

  <h2>{{ subheader }}</h2>
</div>