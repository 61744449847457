import { Injectable } from '@angular/core';
import { Selector, State } from '@ngxs/store';

@State<Object>({
  // ASK what is recommended implementation
  name: 'app_state',
})
@Injectable()
export class AppState {
  @Selector()
  static app_state(state: Object) {
    return state;
  }
}
