import { Component, Input, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-view-fullscreen-img',
  templateUrl: './view-fullscreen-img.component.html',
  styleUrls: ['./view-fullscreen-img.component.scss'],
})
export class ViewFullscreenImgComponent {
  @Input() imageURL = '';

  @Input() removeBorderRadius = false;

  @Input() sizeLarge = false;

  loading = true;

  constructor(private dialog: MatDialog) {}

  openDialogWithTemplateRef(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef);
  }

  onLoad() {
    this.loading = false;
  }
}
