import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { ApiUrls } from '../../shared/constants/api-urls.enum';
import { TokenService } from './token.service';

@Injectable()
export class AuthService {
  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private router: Router,
  ) {}

  login(email: string, password: string) {
    // this.tokenService.removeJwtToken();
    // this.tokenService.removeRefreshToken();

    return this.http
      .post(`${environment.back_api}${ApiUrls.login}`, {
        email,
        password,
      })
      .pipe(
        tap((res: any) => {
          this.tokenService.setJwtToken(res.token);
          this.tokenService.setRefreshToken(res.refreshToken);
          this.router.navigate(['main/offices']);
        }),
      );
  }

  refreshToken(): Observable<any> {
    return this.http
      .post<any>(`${environment.back_api}${ApiUrls.refresh_token}`, {
        refreshToken: this.tokenService.getRefreshToken(),
      })
      .pipe(
        tap((res) => {
          this.tokenService.setJwtToken(res.token);
        }),
        // catchError(AuthService.handleError)
      );
  }

  logout(): void {
    this.tokenService.removeJwtToken();
    this.tokenService.removeRefreshToken();
    this.router.navigate(['auth/login']);
  }

  forgotPassword(email: string): Observable<Object> {
    return this.http.post(`${environment.back_api}${ApiUrls.forgot_password}`, {
      email,
    });
  }
}
