import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'chatRoomsDates',
})
export class ChatRoomsDatesPipe implements PipeTransform {
  transform(value: string | number | Date | undefined): unknown {
    let result = null;
    if (value) {
      const date: Date = new Date(value);
      const diff = Date.now() - date.getTime();
      const dayDif = Math.floor(diff / (1000 * 60 * 60 * 24));
      if (dayDif === 0) result = new DatePipe('en-US').transform(date, 'shortTime');
      else {
        if (dayDif < 7) result = new DatePipe('en-US').transform(date, 'EE');
        else {
          if (dayDif < 360) result = new DatePipe('en-US').transform(date, 'MMM dd');
          else result = new DatePipe('en-US').transform(date, 'MMM dd`yy');
        }
      }
    }
    return result;
  }
}
