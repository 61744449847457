export enum Icons {
  Phone = 'phone-ico',
  Letter = 'letter-ico',
  Lock = 'lock-ico',
  User = 'user-ico',
  Location = 'location-ico',

  Info = 'info-ico',
  Report = 'report-ico',

  Close = 'close-bl',

  // ChatBl = 'chat-bl',
  ChatGr = 'chat-gr',
  // ClientsBl = 'clients-bl',
  ClientsGr = 'clients-gr',
  // EmployeesBl = 'employees-bl',
  EmployeesGr = 'employees-gr',
  // GeneralBl = 'general-bl',
  GeneralGr = 'general-gr',
  // LogoutBl = 'logout-bl',
  LogoutGr = 'logout-gr',
  // OfficeinfoBl = 'officeinfo-bl',
  OfficeinfoGr = 'officeinfo-gr',
  // ProfileBl = 'profile-bl',
  ProfileGr = 'profile-gr',

  BoardGr = 'board-gr',
  CheckpointsGr = 'checkpoints-gr',
  IncidentsGr = 'incidents-gr',
  NotificationsGr = 'notifications-gr',
  ReportsGr = 'reports-gr',
  TasksGr = 'tasks-gr',

  LateShiftsGR = 'lateshifts-gr',
  MissedChekInsGr = 'missedcheckins-gr',
  MissedChekOutsGr = 'missedcheckouts-gr',

  ChatW = 'chat-w',
  NotificationsButtonBl = 'notifications-button-bl',
  PencilButtonBl = 'pencil-button-bl',
  DelBucketBl = 'del-bucket-bl',

  DeleteGr = 'delete-gr',
  DownloadGr = 'download-gr',
  ChevronRightGr = 'chevron-right-gr',
  ChevronLeftGr = 'chevron-left-gr',
  SearchGr = 'search-gr',

  Clip = 'clip',
  EyeBl = 'eye-bl',

  PlusBl = 'plus-bl',
  VoiceBl = 'voice-bl',
  SendBl = 'send-bl',

  Chech = 'check',
  DoubleCheck = 'double-check',
  CheckWh = 'check-wh',
  DoubleCheckWh = 'double-check-wh',

  TrashWh = 'trash-wh',
}
