import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireModule } from '@angular/fire/compat';

import { environment } from 'src/environments/environment';

import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';

import { GlobalErrorHandler } from './services/global-error-handler';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { LoadingInterceptor } from './interceptors/loading.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';

import { AuthService } from './services/auth.service';
import { TokenService } from './services/token.service';
import { LoadingService } from './services/loading.service';
import { NotificationService } from './services/notification.service';
import { IconsService } from './services/icons.service';
import { MessagingService } from './services/messaging.service';
import { SharedModule } from '../shared/shared.module';
import { AppState } from './state/app.store';
import { AuthGuard } from './guards/auth.guard';
import { AlreadyLoggedInGuard } from './guards/already-logged-in.guard';

const config: SocketIoConfig = { url: environment.back_api, options: {} };

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    SharedModule,
    NgxsModule.forRoot([AppState], { developmentMode: !environment.production }),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    // AngularFireDatabaseModule,
    // AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp({
      apiKey: 'AIzaSyAkczOB1Rdh3niQSANZ0c3FQ1hD0On-SVw',
      authDomain: 'security-staging-project.firebaseapp.com',
      projectId: 'security-staging-project',
      storageBucket: 'security-staging-project.appspot.com',
      messagingSenderId: '784858580853',
      appId: '1:784858580853:web:26f5d5491224541ce7f53c',
      measurementId: 'G-1QM0YCF96P',
    }),

    SocketIoModule.forRoot(config),
    // VimeModule,
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    // AngularFireModule.initializeApp(environment.firebaseConfig),
    // AngularFireMessagingModule,
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    AuthService,
    TokenService,
    LoadingService,
    NotificationService,
    IconsService,
    MessagingService,
    AuthGuard,
    AlreadyLoggedInGuard,
  ],
})
export class CoreModule {}
