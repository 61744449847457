export enum NotFoundMsg {
  incidents = 'We can’t find any of your incidents.',
  notifications = 'You don’t have any notifications yet.',
  checkpoints = 'We can’t find any of your checkpoints.',
  reports = 'We can’t find any of your reports.',
  fourofour = 'Page not found. Try again later.',
  clients = 'You haven’t added any clients yet.',
  chats = 'Select chat',
  messages = 'No messages yet',
  tasks = 'No tasks found',
}
