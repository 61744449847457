import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { LoadingService } from '../services/loading.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private loadingService: LoadingService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.includes(environment.back_api)) {
      return next.handle(request);
    }
    // this.loadingService.setLoading(true, request.url);
    return next.handle(request).pipe(
      tap((evt) => {
        // Sent
        if (evt.type === 0) {
          // console.log('evt.type === 0', request.url);
          this.loadingService.setLoading(true, request.url);
        }
        // Response
        if (evt.type === 4) {
          // console.log('evt.type === 4', request.url);
          this.loadingService.setLoading(false, request.url);
        }
        return evt;
      }),
      catchError((err) => {
        this.loadingService.setLoading(false, request.url);
        return throwError(err);
      }),
    ) as Observable<HttpEvent<any>>;
  }
}
