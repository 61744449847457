import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderOption } from '../../interfaces/header-option';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() header!: string;

  @Input() subheader!: string;

  @Input() sel_options!: HeaderOption[] | null;

  @Input() optioned_param!: string;

  constructor(private router: Router, public route: ActivatedRoute) {}

  navigateMenuItem(id: number): void {
    const Q_PARAMS = { ...this.route.snapshot.queryParams, [this.optioned_param]: id };
    this.router.navigate([], {
      // relativeTo: this.route,
      queryParams: Q_PARAMS,
    });
  }
}
