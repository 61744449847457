import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatDialogModule } from '@angular/material/dialog';

import { VimeModule } from '@vime/angular';

import { HeaderComponent } from './components/header/header.component';
import { LoadingComponent } from './components/loading/loading.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ShowMessageComponent } from './components/show-message/show-message.component';
import { EmptyListComponent } from './components/empty-list/empty-list.component';
import { ViewFullscreenImgComponent } from './components/view-fullscreen-img/view-fullscreen-img.component';
import { ViewFullscreenVideoComponent } from './components/view-fullscreen-video/view-fullscreen-video.component';
import { CarouselComponent } from './components/carousel/carousel.component';

import { RolePipe } from './pipes/role.pipe';
import { MobilePhonePipe } from './pipes/mobile-phone.pipe';
import { CircledLettersPipe } from './pipes/circled-letters.pipe';
import { ChatRoomsDatesPipe } from './pipes/chat-rooms-dates.pipe';

@NgModule({
  declarations: [
    HeaderComponent,
    LoadingComponent,
    ConfirmDialogComponent,
    RolePipe,
    ShowMessageComponent,
    MobilePhonePipe,
    EmptyListComponent,
    CircledLettersPipe,
    ChatRoomsDatesPipe,
    ViewFullscreenImgComponent,
    ViewFullscreenVideoComponent,
    CarouselComponent,
  ],
  imports: [
    CommonModule,
    GoogleMapsModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    VimeModule,
  ],
  exports: [
    HeaderComponent,
    LoadingComponent,
    ConfirmDialogComponent,
    ShowMessageComponent,
    EmptyListComponent,
    ViewFullscreenImgComponent,
    ViewFullscreenVideoComponent,
    RolePipe,
    MobilePhonePipe,
    CircledLettersPipe,
    ChatRoomsDatesPipe,
    CarouselComponent,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
  ],
})
export class SharedModule {}
