import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'circledLetters',
})
export class CircledLettersPipe implements PipeTransform {
  transform(value: any): unknown {
    if (value) {
      const firstTwoWords: string[] = value.split(/\s/).slice(0, 2);
      const res: string =
        firstTwoWords.length > 1 ? firstTwoWords[0][0] + firstTwoWords[1][0] : firstTwoWords[0][0];
      return res.toUpperCase();
    }
    return null;
  }
}
