import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';

import { Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { LoadingService } from 'src/app/modules/core/services/loading.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit, OnDestroy {
  loading = false;

  private ngUnsubscribe = new Subject();

  constructor(private loadingService: LoadingService, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.listenToLoading();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /**
   * Listen to the loadingSub property in the LoadingService class. This drives the
   * display of the loading spinner.
   */
  listenToLoading(): void {
    this.loadingService.loadingSub
      .pipe(delay(0), takeUntil(this.ngUnsubscribe)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        // console.log('loading:', loading);
        this.loading = loading;
        this.cdr.detectChanges();
      });
  }
}
