<div *ngIf="loading" class="spinner-holder">
  <mat-progress-spinner color="primary" mode="indeterminate"> </mat-progress-spinner>
</div>
<img
  [hidden]="loading"
  (load)="onLoad()"
  (click)="openDialogWithTemplateRef(myDialog)"
  [src]="imageURL"
  [ngClass]="{ preview_img: true, no_border: removeBorderRadius, size_large:sizeLarge }"
/>
<ng-template #myDialog>
  <img class="full-screen-img" [src]="imageURL" />
</ng-template>
