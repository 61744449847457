export enum ApiUrls {
  login = '/auth/signin',
  forgot_password = '/auth/forgot/password',
  refresh_token = '/auth/refresh-token',
  firebase_token = '/auth/save-firebase-device',

  users = '/users',
  get_me_user = '/users/me',
  change_contacts = '/users/settings',
  change_password = '/auth/change-my-password',
  offices = '/offices',
  add_user = '/auth/signup',
  change_status = '/users/switch-user-status',

  clients = '/clients',
  daily_activity_report = '/daily-activity-report',
  summary_report = '/summary-report',
  auto_send_report = '/auto-send-report',
  posts = '/posts',
  missed_activity = '/missed-activity',

  checkpoints = '/checkpoints',
  pdf = '/pdf',
  switch_checkpoint_status = '/switch-checkpoint-status',
  switch_send_report_status = '/switch-send-report-status',

  incidents = '/issues',
  incidents_types = '/issues-type',

  notifications = '/notifications',
  reports = '/report',
  preview = '/preview',
  send_to_client = '/send-to-client',
  status_update = '/status-update',
  // reports = '/reports',

  chat_rooms = '/chat-rooms',
  all = '/all',
  messages = '/messages',
  read_messages = '/read-messages',
  attach_media = '/attach-media',

  tasks = '/tasks',
  task_list = '/task-list',

  checklist = '/checklist',
  daily_checklists = '/daily-checklists',

  time_shift = '/time-shift',
  missed = '/missed',
  time_in = '/time-in',
  time_out = '/time-out',
}
