import { Component, OnInit } from '@angular/core';
// import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IconsService } from './modules/core/services/icons.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  title = 'security-frontend';

  constructor(private iconService: IconsService) {}

  ngOnInit() {
    // TODO what is the best place to register icons?
    this.iconService.registerIcons();
  }
}
