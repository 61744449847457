import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { ApiUrls } from '../../shared/constants/api-urls.enum';

import { ShowMessageComponent } from '../../shared/components/show-message/show-message.component';

@Injectable()
export class MessagingService {
  currentMessage$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  openMessage$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient, private snackBar: MatSnackBar) {}

  setNotification(notification: any) {
    this.currentMessage$.next(notification);
  }

  getNotification() {
    return this.currentMessage$.asObservable();
  }

  sendFirebaseToken(token: string | null): Observable<Object> {
    return this.http.post(`${environment.back_api}${ApiUrls.firebase_token}`, {
      firebaseToken: token,
    });
  }

  showMessage(data: any) {
    this.snackBar.openFromComponent(ShowMessageComponent, {
      data,
      duration: 10000,
    });
  }
}
