import { Pipe, PipeTransform } from '@angular/core';
import { preparePhoneForShow } from '../forms-helper/forms-helper';

@Pipe({
  name: 'mobilePhone',
})
export class MobilePhonePipe implements PipeTransform {
  transform(value: any): any {
    if (value) return preparePhoneForShow(value);
    return value;
  }
}
