import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

@Injectable()
export class NotificationService {
  options = {
    horizontalPosition: 'right' as MatSnackBarHorizontalPosition,
    verticalPosition: 'top' as MatSnackBarVerticalPosition,
    duration: 4000,
  };

  constructor(private snackBar: MatSnackBar) {}

  showError(message: string): void {
    this.snackBar.open(message, 'ok', this.options);
  }

  showSuccess(message: string): void {
    // TODO add styling options
    this.snackBar.open(message, 'ok', this.options);
  }

  showWarning(message: string): void {
    this.snackBar.open(message, 'ok', this.options);
  }
}
