import { Component, Input, OnInit } from '@angular/core';
import { MediaAttached } from '../../interfaces/media-attached';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnInit {
  @Input() media!: MediaAttached[] | undefined;

  counter: number = 0;

  imagesAndVideos: MediaAttached[] = [];

  audios: MediaAttached[] = [];

  constructor() {}

  ngOnInit(): void {
    this.media?.forEach((item: MediaAttached) => {
      item.type === 'AUDIO' ? this.audios.push(item) : this.imagesAndVideos.push(item);
    });
  }

  onNext() {
    if (this.counter !== this.imagesAndVideos.length - 1) {
      this.counter += 1;
    }
  }

  onPrevious() {
    if (this.counter > 0) {
      this.counter -= 1;
    }
  }
}
