import { Component, Input, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-view-fullscreen-video',
  templateUrl: './view-fullscreen-video.component.html',
  styleUrls: ['./view-fullscreen-video.component.scss'],
})
export class ViewFullscreenVideoComponent {
  @Input() videoURL = '';

  @Input() removeBorderRadius = false;

  @Input() sizeLarge = false;

  constructor(private dialog: MatDialog) {}

  openDialogWithTemplateRef(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef);
  }
}
