import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';
import { TokenService } from '../services/token.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private notificationService: NotificationService,
    private tokenService: TokenService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err) => {
        console.log('catchError', err);
        const ERR_MESSAGE = (err && err.error && err.error.message) || err.statusText;
        if (
          err instanceof HttpErrorResponse &&
          err.status === 401 &&
          this.tokenService.jwtTokenExists()
        ) {
          console.error('401 in error interceptor');
          // return next.handle(request);   <-- this sends the same request one more time
          return throwError(err);
        }
        console.error('ErrorInterceptor says:', err);
        this.notificationService.showError(ERR_MESSAGE);
        return throwError(err);
      }),
    );
  }
}
