import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-show-message',
  templateUrl: './show-message.component.html',
  styleUrls: ['./show-message.component.scss'],
})
export class ShowMessageComponent {
  constructor(
    public snackBarRef: MatSnackBarRef<ShowMessageComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private router: Router,
    public route: ActivatedRoute,
  ) {}

  goToMessage() {
    const Q_PARAMS = {
      office: this.data.data.officeId,
      client: this.data.data.clientId,
      post: this.data.data.postId,
      notification: this.data.data.notificationId,
    };
    this.router.navigate(['/main/clients/item/notifications'], {
      queryParams: Q_PARAMS,
    });
  }
}
