import { TitleCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'role',
})
export class RolePipe extends TitleCasePipe implements PipeTransform {
  transform(value: any): any {
    // transform(value: any, ...args: unknown[]): any {
    if (typeof value === 'string') {
      if (value === 'DAR') return 'Daily Activity Report'; // hardcoded thing for reports module
      return super.transform(value.replace('_', ' '));
    }
    return null;
  }
}
