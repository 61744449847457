import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { AuthService } from '../services/auth.service';
import { TokenService } from '../services/token.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;

  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private authService: AuthService, private tokenService: TokenService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.includes(environment.back_api)) {
      return next.handle(request);
    }

    if (this.tokenService.jwtTokenExists()) {
      request = this.addToken(request, this.tokenService.getJwtToken());
    }

    return next.handle(request).pipe(
      catchError((err) => {
        if (
          err instanceof HttpErrorResponse &&
          err.status === 401 &&
          this.tokenService.jwtTokenExists()
        ) {
          // if (!this.tokenService.jwtTokenExists()) {
          //   return throwError(err);
          // }
          return this.handle401Error(request, next);
        }
        return throwError(err);
      }),
    );
  }

  private addToken(request: HttpRequest<any>, token: string | null) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log('handle401Error');
    // console.log(request);
    // console.log('this.isRefreshing:', this.isRefreshing);

    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      return this.authService.refreshToken().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.token);
          return next.handle(this.addToken(request, token.token));
        }),

        catchError((err) => {
          this.authService.logout();
          return throwError(err);
        }),
      );
    }
    return this.refreshTokenSubject.pipe(
      // filter((token) => token != null),
      take(1),
      switchMap((jwt) => {
        if (!jwt) {
          this.authService.logout();
          return throwError('Refresh token broken');
        }
        return next.handle(this.addToken(request, jwt));
      }),
    );
  }
}
